/* Logo styles */
.logo {
  display: none;
}
@media print {
  .logo {
    display: block;
    width: 150px !important;
  }

  .logo img {
    width: 150px !important;
    height: auto !important;
    max-width: 150px !important;
  }
}

.headerSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 40px;
  gap: 20px;
}

.companyTitle {
  font-size: 24px;
  font-weight: bold;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
}

.currentDate {
  font-size: 16px;
}

/* Hidden element styles */
.hidden {
  display: flex;
}
@media print {
  .hidden {
    display: none !important;
  }
}

/* Print active styles */
.printActive {
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 20px;
}

/* Table and notes styles */
@media print {
  .table,
  .notes {
    margin-left: 10px;
    margin-right: 10px;
  }
}

/* Table styles */
.table {
  display: none;
}
@media print {
  .table {
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 20px;
    color: #000 !important;
  }
}

/* Grid styles */
@media print {
  .grid {
    display: none !important;
  }
}

/* Table element styles */
@media print {
  .table table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  .table th,
  .table td {
    border: 1px solid #000;
    padding: 8px;
    text-align: center;
  }

  .table th {
    background-color: #8298c0 !important;
    color: #000;
    font-weight: bold;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .table tr:nth-child(even) {
    background-color: #f2f2f2;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

/* Summary styles */
.bottomSection {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 20px;
  page-break-inside: avoid;
  break-inside: avoid;
}

@media print {
  .summary {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .summaryBox {
    padding: 20px;
    border: 1px solid #000;
    border-radius: 4px;
    width: 60%;
    background-color: #f8f9fa;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .summaryItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    font-size: 16px;
    border-bottom: 1px solid #eee;
  }

  .summaryItem:last-child {
    border-bottom: none;
  }

  .summaryItem strong {
    font-size: 18px;
    margin-left: 20px;
  }
}

/* Signatures styles */
.signatures {
  display: none;
}
@media print {
  .signatures {
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    padding: 0 50px;
  }

  .signature {
    text-align: center;
  }

  .signatureLine {
    width: 200px;
    border-bottom: 1px solid black;
    margin-bottom: 10px;
  }
}

/* Negative number styles */
@media print {
  .negativeNumber {
    color: red !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

/* Page break styles */
@media print {
  .pageBreak {
    page-break-before: always;
  }
}

.pageContainer {
  margin-bottom: 20px;
}

.accountTitle {
  text-align: center;
  margin: 20px 0;
  font-size: 1.5em;
  font-weight: bold;
}

@media print {
  .pageContainer {
    page-break-after: always;
  }

  .pageContainer:last-child {
    page-break-after: avoid;
  }

  .accountTitle {
    margin: 20px 0;
    color: #000;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
